@font-face { 
  font-family: HelveticaNeueLTStd; 
  src: url(../public/fonts/HelveticaNeueLTStd-MdCn.woff2);
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.default{
  background: linear-gradient(135deg, rgba(245, 246, 247, 1) 50%, rgba(0,159,255,1) 100%);
}
.dark{
  background-color: rgb(48, 48, 48, 1);
}
.black{
  background-color: black;
  font-family: HelveticaNeueLTStd;
  line-height: 1.5;
  font-size: 1.25rem;
}